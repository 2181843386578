import React from "react"
import { List } from "antd"

const NoDataScreen = () => {
  return (
    <List data={[]} />
  )
}

export default NoDataScreen
